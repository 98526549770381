/**
 * Get the appropriate base URL for Partner Portal based on where Admin Portal is running
 * @returns string
 */
export function getPartnerPortalUrl() {
  let baseUrl
  if (window.location.href.includes('localhost')) {
    baseUrl = 'http://localhost:3000'
  } else if (window.location.href.includes('dev')) {
    baseUrl = 'https://dev-partner-portal.beamimpact.com'
  } else if (window.location.href.includes('vercel')) {
    baseUrl = 'https://dev-partner-portal.beamimpact.com'
  } else if (window.location.href.includes('staging')) {
    baseUrl = 'https://staging-partner-portal.beamimpact.com'
  } else {
    baseUrl = 'https://partner-portal.beamimpact.com'
  }
  return baseUrl
}
