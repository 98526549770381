import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { fetchPartners } from '../../redux/thunks/adminThunks'
import {
  clearNewKey,
  createApiKey,
  fetchApiKeys,
  updateApiKey,
} from '../../redux/thunks/partnerThunks'
import { BeamCheckbox } from '../../stories/BeamCheckbox/BeamCheckbox'
import styles from '../../style/root/api-keys.module.css'
import dropdownStyles from '../../style/root/beam-dropdown.module.css'
import modalStyles from '../../style/root/beam-modal.module.css'
import { isAdminOrSuper } from '../../utils/helpers/isAdminOrSuper'
import { TUser } from '../../utils/types'
import type { TChainApiKey } from '../../utils/types/ChainApiKey'
import { LoginPage } from '../root/LoginPage'
import { APIError } from './APIError'
import BeamButton from './BeamButton'
import BeamDropdown from './BeamDropdown'
import { BeamModal } from './BeamModal'
import BeamTable from './BeamTable'
import { BEAM_COLORS } from './constants'
import { PageNotFound } from './PageNotFound'
import { WelcomeNote } from './WelcomeNote'

// Note: The TPPPartner type doesn't reflect the response object. This is a hotfix to match the response shape. Since its a v1 endpoint, its also not types on the backend schema
interface PartnerType {
  beamInvoices: number
  canSeeAutopayFeatures: boolean
  createdAt: string
  donateForUnredeemed: boolean
  id: number
  inKindDonations: boolean
  isActive: boolean
  lastTransactionDate: null
  launchDate: string
  locations: null
  name: string
  nonprofitInvoices: number
  paymentStructure: { name: string }
  paymentStructureId: number
  sdkId: number
  updatedAt: string
  usePpgf: boolean
}
// Headers visible to anyone that isn't an admin
const HEADERS = [
  {
    field: 'prefix',
    headerName: 'Prefix',
    dataType: 'string',
  },
  { field: 'name', headerName: 'Name', dataType: 'string' },
  { field: 'status', headerName: 'Status', dataType: 'string' },
  { field: 'scope', headerName: 'Scope', dataType: 'string' },
  {
    field: 'revoke',
    headerName: '',
    dataType: 'string',
  },
]

// Headers visible to admin users
const ADMIN_HEADERS = [
  {
    field: 'prefix',
    headerName: 'Prefix',
    dataType: 'string',
  },
  { field: 'name', headerName: 'Name', dataType: 'string' },
  { field: 'createdBy', headerName: 'Created By', dataType: 'string' },
  { field: 'partner', headerName: 'Partner', dataType: 'string' },
  { field: 'status', headerName: 'Status', dataType: 'string' },
  { field: 'scope', headerName: 'Scope', dataType: 'string' },
  {
    field: 'revoke',
    headerName: '',
    dataType: 'string',
  },
]

const ApiKeys = () => {
  const [creationModalIsOpen, setCreationModalIsOpen] = useState(false)

  // new api key state
  const [scope, setScope] = useState('back end')
  const [expiration, setExpiration] = useState('')
  const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(null)
  const [keyName, setKeyName] = useState('')
  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false)
  const [isPrimaryKey, setIsPrimaryKey] = useState(false)

  const [cachedPartners, setCachedPartners] = useState<PartnerType[]>([])

  // FIXME: Remove all `any` and typecasting when whe have typedefs
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const user = useBeamSelector(({ user }) => user) as TUser | undefined
  const apiKeys = useBeamSelector(({ apiKeys }) => apiKeys) as TChainApiKey[] | undefined
  const newApiKey = useBeamSelector(({ newApiKey }) => newApiKey)
  const displayModalIsOpen = !!newApiKey
  const partners = useBeamSelector(({ partners }) => partners) as PartnerType[] | undefined
  const dispatch = useDispatch()

  const expirationOptions = useMemo(() => {
    const now = new Date()
    const thisMonth = now.getMonth()
    const thisYear = now.getFullYear()
    const options = [
      { value: '', display: 'Never' },
      { value: new Date(now.setMonth(thisMonth + 3)).toISOString(), display: '3 Months' },
      { value: new Date(now.setMonth(thisMonth + 6)).toISOString(), display: '6 Months' },
      { value: new Date(now.setFullYear(thisYear + 1)).toISOString(), display: '1 Year' },
    ]
    return options
  }, [])

  // Fetch partners
  useEffect(() => {
    if (!partners?.length && !loadingStates?.partners?.loading && !loadingStates?.partners?.error) {
      dispatch(fetchPartners())
    }
  }, [partners, loadingStates, dispatch, apiKeys])

  // Fetch api keys
  useEffect(() => {
    dispatch(fetchApiKeys())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPartners = () => {
    if (!partners?.length) return []

    if (!cachedPartners.length) {
      const sortedPartners = partners.sort(({ name: name1 }, { name: name2 }) => {
        const name1Lc = name1.toLocaleLowerCase()
        const name2Lc = name2.toLocaleLowerCase()
        if (name1Lc < name2Lc) return -1
        if (name1Lc > name2Lc) return 1
        return 0
      })
      setCachedPartners(sortedPartners)
      return sortedPartners
    }

    return cachedPartners
  }

  const tableRows = useMemo(() => {
    if (!apiKeys) return []

    // XXX - this will go away once we move towards using the chainApiKeyScopes
    // table by leveraging the description column in place of searching for keywords
    const getDisplayScope = (scopes?: { [index: string]: string[] }) => {
      if (!scopes || typeof scopes !== 'object') return '-'
      const scopeKeys = Object.keys(scopes)
      if (scopeKeys.some(scope => scope.indexOf('transaction') > -1)) {
        return 'back end'
      } else if (scopeKeys.some(scope => scope.indexOf('sync') > -1)) {
        return 'order sync'
      }
      return 'front end'
    }

    return apiKeys.map(key => {
      const row = {
        prefix: key.prefix,
        status: key.revoked ? 'revoked' : 'active',
        scope: getDisplayScope(key.scopes),
        name: key.name,
        partner: isAdminOrSuper(user?.type) ? (key.chain && key.chain.name) || '-' : null,
        createdBy: isAdminOrSuper(user?.type) ? key.createdBy ?? 'Redacted' : null,
        revoke: {
          value: (
            <BeamButton
              text={key.revoked ? 'revoked' : 'revoke'}
              disabled={key.revoked}
              style={{ width: '90px', height: '28px', backgroundColor: BEAM_COLORS.orange }}
              handler={() =>
                dispatch(
                  updateApiKey({
                    scope: getDisplayScope(key.scopes),
                    expiryDate: key.expiryDate,
                    revoked: !key.revoked,
                    prefix: key.prefix,
                    chainId: key.chainId,
                  })
                )
              }
            />
          ),
        },
      }

      return row
    })
  }, [apiKeys, user?.type, dispatch])

  const submitKey = () => {
    if (!scope || expiration === undefined) return
    return dispatch(
      createApiKey({
        scope,
        expiryDate: expiration,
        selectedPartner,
        keyName,
        isPrimary: isPrimaryKey,
      })
    )
  }

  const flushNewApiKeyState = () => {
    setScope('front end')
    setExpiration('')
    setSelectedPartner(null)
    setKeyName('')
  }

  const tableIsLoading = useMemo(() => {
    return !!loadingStates?.apiKeys?.loading?.getting || !!loadingStates?.apiKeys?.loading?.updating
  }, [loadingStates?.apiKeys])

  const tableLoadingMessage = useMemo(() => {
    if (loadingStates?.apiKeys?.loading?.getting) return 'Getting your API keys...'
    if (loadingStates?.apiKeys?.loading?.updating) return 'Submitting your updates...'
    return null
  }, [loadingStates?.apiKeys])

  if (loadingStates.user && loadingStates.user.loading) return <div>One moment please...</div>
  if (loadingStates.user && loadingStates.user.error)
    return <APIError error={loadingStates.user.error} />
  if (!user?.type) return <LoginPage />
  if (['Admin', 'Engineering', 'Super'].indexOf(user.type) === -1) return <PageNotFound />

  return (
    <div className={styles.container}>
      <WelcomeNote
        firstName={user?.firstName || undefined}
        lastName={user?.lastName || undefined}
        pageTitle="Your Api Keys"
      />

      <div className={styles['button-container']}>
        <BeamButton
          text="Create Key"
          handler={() => setCreationModalIsOpen(true)}
          disabled={user.type !== 'Engineering' && user.type !== 'Super' && user.type !== 'Admin'}
        />
      </div>

      <BeamTable
        headers={isAdminOrSuper(user?.type) ? ADMIN_HEADERS : HEADERS}
        rows={tableRows}
        defaultSortColumn="prefix"
        emptyTableMessage="You don't have any api keys yet"
        loading={tableIsLoading}
        loadingMessage={tableLoadingMessage}
      />

      <div className={styles['button-container']}>
        <BeamButton
          text="Create Key"
          handler={() => setCreationModalIsOpen(true)}
          disabled={user.type !== 'Engineering' && user.type !== 'Super' && user.type !== 'Admin'}
        />
      </div>

      <BeamModal
        title="Create Your API Key"
        open={!!(creationModalIsOpen && !newApiKey)}
        handleClose={() => setCreationModalIsOpen(false)}>
        {loadingStates?.apiKeys?.creating?.loading ? (
          <div>Submitting Your Key...</div>
        ) : (
          <div className={modalStyles['modal-container']}>
            <div className={'w-full justify-around mb-5'}>
              <div className={'flex justify-around items-center w-full'}>
                <div className={'max-w-[60%] font-bold'}>Is Primary?:</div>

                <div>
                  <BeamCheckbox
                    checked={isPrimaryKey}
                    onChange={e => setIsPrimaryKey(e.target.checked)}
                    name={'isPrimary'}
                  />
                </div>
              </div>

              <div className={'italic text-sm'}>
                &quot;Primary&quot; means this is the key primarily used by the partner&apos;s
                integration
              </div>
            </div>

            <div className={styles['select-container']}>
              Scope:
              <BeamDropdown
                options={['back end', 'orderSync']}
                selectedValue={scope}
                padding={{ top: '5px', bottom: '5px', left: '5px', right: '5px' }}
                width="120px"
                changeHandler={(_, value) => setScope(value as string)}
              />
            </div>
            <div className={styles['select-container']}>
              Expires:
              <BeamDropdown
                options={expirationOptions}
                selectedValue={expiration}
                padding={{ top: '5px', bottom: '5px', left: '5px', right: '5px' }}
                width="120px"
                changeHandler={(_, value) => setExpiration(value as string)}
                disabled={true}
              />
            </div>
            {isAdminOrSuper(user?.type) && (
              <div className={styles['select-container']}>
                Partner:
                <BeamDropdown
                  options={getPartners().map(p => ({ display: p.name, value: p.sdkId }))}
                  selectedValue={selectedPartner}
                  padding={{ top: '5px', bottom: '5px', left: '5px', right: '5px' }}
                  width="280px"
                  changeHandler={(_, value) => setSelectedPartner(value as PartnerType)}
                />
              </div>
            )}
            {isAdminOrSuper(user?.type) && (
              <div className={styles['select-container']}>
                Key Name:
                <input
                  className={dropdownStyles['beam-dropdown-placeholder']}
                  value={keyName}
                  style={{ cursor: 'text', maxWidth: '287px' }}
                  onChange={e => setKeyName(e.target.value)}
                />
              </div>
            )}
            <div className={styles['select-container']}>
              <div style={{ marginRight: '15px' }}>Created By:</div>
              <div style={{ cursor: 'not-allowed', color: 'rgb(0, 0, 0, 0.38)' }}>
                {user?.firstName} {user?.lastName}
              </div>
            </div>
            {loadingStates.apiKeys && loadingStates.apiKeys.error && (
              <div className={styles.error}>{loadingStates.apiKeys.error.toString()}</div>
            )}
            <BeamButton text="SUBMIT" handler={() => submitKey()} />
          </div>
        )}
      </BeamModal>
      <BeamModal
        open={displayModalIsOpen}
        title="Here's your new API key"
        handleClose={() => {
          dispatch(clearNewKey())
          setCreationModalIsOpen(false)
          setCopiedToClipBoard(false)
          flushNewApiKeyState()
        }}>
        <div className={modalStyles['modal-container']}>
          <div>&#x26a0;&#xfe0f; You can only view this once! &#x26a0;&#xfe0f;</div>
          <div className={styles['key-display']}>
            {copiedToClipBoard && (
              <div className={styles['copy-to-clipboard']}>Copied to clipboard</div>
            )}
            <div
              onClick={() => {
                navigator.clipboard.writeText(newApiKey)
                setCopiedToClipBoard(false)
              }}>
              {newApiKey}
            </div>
          </div>
        </div>
      </BeamModal>
    </div>
  )
}

export default ApiKeys
