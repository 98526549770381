import { API_BASE_PATH_PORTAL } from '../../api/auth'
import { axiosRequest } from '../../utils/axiosRequest'
import {
  setApiKeys,
  setImpact,
  setLoadingApiKyes,
  setLoadingImpact,
  setLoadingSupportEmail,
  setNewApiKey,
} from '../actions'

export const fetchImpact = partnerId => {
  return dispatch => {
    dispatch(setLoadingImpact({ loading: true, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/impact/${partnerId}`)
      .then(res => {
        dispatch(setImpact(res.data))
        dispatch(setLoadingImpact({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingImpact({ loading: false, error }))
      })
  }
}

export const fetchApiKeys = () => {
  return dispatch => {
    dispatch(setLoadingApiKyes({ loading: { getting: true }, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/v2/api-keys/`)
      .then(res => {
        dispatch(setApiKeys(res.data))
        dispatch(setLoadingApiKyes({ loading: { getting: false }, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingApiKyes({ loading: { getting: false }, error }))
      })
  }
}

export const createApiKey = ({ scope, expiryDate, selectedPartner, keyName, isPrimary }) => {
  return dispatch => {
    dispatch(setLoadingApiKyes({ loading: { creating: true }, error: null }))
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/v2/api-keys`, {
      scope,
      expiryDate,
      chainId: Number(selectedPartner),
      name: keyName,
      isPrimary,
    })
      .then(res => {
        dispatch(setLoadingApiKyes({ loading: { creating: false }, error: null }))
        dispatch(setNewApiKey(`${res.data.prefix}.${res.data.key}`))
        dispatch(fetchApiKeys())
      })
      .catch(error => {
        dispatch(
          setLoadingApiKyes({
            loading: { creating: false },
            error: JSON.stringify(
              (error && error.response && error.response.data && error.response.data.error) || error
            ),
          })
        )
      })
  }
}

export const updateApiKey = ({ scope, expiryDate, revoked, prefix, chainId }) => {
  return dispatch => {
    dispatch(setLoadingApiKyes({ loading: { updating: true }, error: null }))
    axiosRequest('put', `${API_BASE_PATH_PORTAL}/v2/api-keys/`, {
      scope,
      expiryDate,
      revoked,
      prefix,
      chainId,
    })
      .then(() => {
        dispatch(setLoadingApiKyes({ loading: { updating: false }, error: null }))
        dispatch(fetchApiKeys())
      })
      .catch(error => {
        dispatch(
          setLoadingApiKyes({
            loading: { updating: false },
            error: JSON.stringify(
              (error && error.response && error.response.data && error.response.data.error) || error
            ),
          })
        )
      })
  }
}

export const clearNewKey = () => {
  return dispatch => {
    dispatch(setNewApiKey(''))
  }
}

export const sendSupportEmail = (emailData, partnerId) => {
  return dispatch => {
    dispatch(setLoadingSupportEmail({ loading: true, error: null, success: null }))
    axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/v2/support`,
      {
        message: emailData.message,
        subject: emailData.subject,
        name: emailData.name,
        email: emailData.email,
      },
      { partnerId }
    )
      .then(() => {
        dispatch(setLoadingSupportEmail({ loading: false, error: null, success: true }))
      })
      .catch(error => {
        dispatch(setLoadingSupportEmail({ loading: false, error, success: false }))
      })
  }
}
